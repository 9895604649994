import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Text } from '@chakra-ui/react';
import Header from './Header';

const PrivacyPolicy = () => {
  const policyContent = `

ShareDot, Inc (“we,” “us,” or “our”) wants you to be familiar with how we collect, use, and disclose information.  This Privacy Policy describes our practices in connection with information that we collect through: 
- Website(s) operated by us from which you are accessing this Privacy Policy;
- Software application(s) made available by us for use on or through computers and mobile devices;
- Our social media pages; and
- HTML-formatted email messages that we send to you that link to this Privacy Policy.
Collectively, we refer to the website(s), app(s), social media pages, and emails as the “Services.” 

## Personal Information
“**Personal Information**” is information that identifies you as an individual or relates to an identifiable individual.  We collect Personal Information through or in connection with the Services, such as:
- Name
- Postal address
- Telephone number
- Email address 
- IP address (we may also derive your approximate location from your IP address)
- Payment information
- Social media account ID

## Collection of Personal Information 
We and our service providers collect Personal Information in a variety of ways, including:\n
*Through the Services*
- We collect Personal Information through the Services, such as when you sign up for a newsletter, create an account, contact customer service, or make a purchase.

*From Other Sources*
- We receive your Personal Information from other sources, such as publicly available databases.
- If you connect your social media account to your Services account, you will share certain Personal Information from your social media account with us, including your name, email address, photo, connections, and any other information that may be accessible to us when you connect your accounts.

We need to collect Personal Information in order to provide the requested Services to you.  If you do not provide the information requested, we may not be able to provide the Services.  If you disclose any Personal Information relating to other people to us or to our service providers in connection with the Services, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy.  

## Use of Personal Information 
We and our service providers use Personal Information for the following purposes \n\n
*Providing the functionality of the Services and fulfilling your requests*. 
- To provide the Services’ functionality to you, such as arranging access to your account.
- To complete your transactions, process cancellations, returns, and exchanges, and verify your information.
- To provide you with benefits, promotions, and customer service.
- To respond to your inquiries and fulfill your requests.
- To send administrative information to you, such as changes to our terms, conditions, and policies. 

*Providing you with our newsletter and/or other marketing materials and facilitating social sharing*.
- To send you marketing related emails. 
- To facilitate social sharing functionality that you choose to use.

*Analyzing Personal Information for business reporting and providing personalized services*. 
- To analyze use of the Services. 
- To better understand your interests and preferences, so that we can personalize our interactions with you.

*Aggregating and/or anonymizing Personal Information*.
- We may aggregate and/or anonymize Personal Information so that it will no longer be considered Personal Information.  We do so to generate other data for our use, which we may use and disclose for any purpose, as it no longer identifies you or any other individual. 

*Accomplishing our business purposes*. 
- For audits, to verify that our internal processes function as intended and to address legal, regulatory, or contractual requirements; 
- For fraud prevention and security monitoring purposes, including to detect and prevent cyberattacks or attempts to commit identity theft; 
- For developing new products and services; 
- For enhancing, improving, repairing, maintaining, or modifying our current products and services, as well as undertaking quality and safety assurance measures; 
- For identifying usage trends, for example, understanding which parts of our Services are of most interest to users; 
- For determining the effectiveness of our promotional campaigns; and 
- For operating and expanding our legitimate business activities.  

## Disclosure of Personal Information\n\n
We disclose Personal Information: 

*To our service providers, to facilitate services they provide to us*.
- These can include providers of such services as website hosting, data analysis, payment processing, order fulfillment, fraud prevention, information technology and related infrastructure provision, customer service, email delivery, and auditing.

*By using the Services, you may elect to disclose Personal Information*.
- On message boards, chat, profile pages, blogs, and other services to which you are able to post information and content.  Any information you post or disclose through these services may be available to other users and the general public. 
- Through your social sharing activity.  When you connect your Services account to your social media account, you will share information with your friends associated with your social media account, with other users, and with your social media account provider.  By doing so, you authorize us to facilitate this sharing, and you understand that the use of shared information will be governed by the social media provider’s privacy policy.

## Other Uses and Disclosures

We may also use and disclose Personal Information as we believe to be necessary or appropriate:  (a) to comply with applicable law, to respond to warrants pertaining to active investigations, and to fulfill other legal obligations; (b) to enforce our terms and conditions; and (c) to protect our rights, privacy, safety, or property, and/or that of you or others.  We may use, disclose, or transfer Personal Information to a third party in connection with any proposed or actual reorganization, bankruptcy, merger, sale, joint venture, assignment, transfer, or other disposition of all or any portion of our assets or stock.

## Information Collected Automatically
We and our service providers may collect information automatically in a variety of ways, including:
  
*Your browser or device*  
- Certain information is collected by most browsers or automatically through your device, such as your Media Access Control (MAC) address, computer type (Windows or Mac), screen resolution, operating system name and version, device manufacturer and model, language, and Internet browser type and version.  We use this information to ensure that the Services function properly.  

*Your use of our app(s)*
- When you download and use our app, we and our service providers may track and collect usage data, such as the date and time the app on your device accesses our servers and what information and files have been downloaded to the app based on your device number.  We use this information to understand how the app is used and improve it.  

*Cookies*
- Cookies are pieces of information stored directly on your computer.  Cookies allow us to collect such information as browser type, time spent on the Services, pages visited, language preferences, and traffic data.  We use the information for security purposes, to facilitate navigation, to display information more effectively, and to personalize your experience.  We also gather statistical information about use of the Services in order to continually improve their design and functionality, understand how they are used, and assist us with resolving questions regarding them.  We do not currently respond to browser do-not-track signals.  If you do not want information collected through the use of cookies, most browsers allow you to automatically decline cookies or be given the choice of declining or accepting particular cookies from a particular website.  You may also wish to refer to http://www.allaboutcookies.org/manage-cookies/index.html. If, however, you do not accept cookies, you may experience some inconvenience in your use of the Services. 

*Pixel tags and other similar technologies*
- We may use pixel tags (also known as web beacons and clear GIFs) to, among other things, track the actions of users of the Services (including email recipients), measure the success of our marketing campaigns, and compile statistics about use of the Services and response rates.  

*Analytics*
- We use analytics services, such as Google Analytics, which use cookies and similar technologies to collect and analyze information about use of the Services and report on activities and trends.  These services may also collect information regarding the use of other websites, apps, and online services. You can exercise the opt-out provided by Google by downloading the [Google Analytics opt-out browser add-on](https://tools.google.com/dlpage/gaoptout).

*Physical Location*
- We may collect the approximate physical location of your device by, for example, using satellite, cell phone tower, or Wi-Fi signals.  We may use your device’s physical location to provide you with personalized location-based services and content.  In some instances, you may be permitted to allow or deny such use of your device’s location, but, if you do, we may not be able to provide you with the applicable personalized services and content.

## Uses and Disclosures of Information Collected Automatically 

If we are required to treat such information collected automatically as Personal Information under applicable law, we may use and disclose it for the purposes for which we use and disclose Personal Information, as detailed in this Privacy Policy.  In some instances, we may combine information collected automatically with Personal Information.  If we do, we will treat the combined information as Personal Information so long as it is combined.

## Security
We seek to use reasonable organizational, technical, and administrative measures to protect Personal Information within our organization.  Unfortunately, no data transmission or storage system can be guaranteed to be 100% secure.  If you have reason to believe that your interaction with us is no longer secure, please immediately notify us in accordance with the “Contact Us” section below.

## Third-Party Services
This Privacy Policy does not address, and we are not responsible for, the privacy, information, or other practices of any third parties, including any third party operating a website or service to which the Services link.  The inclusion of a link on the Services does not imply our endorsement of the linked site or service. 
In addition, we are not responsible for the information collection, use, disclosure, or security policies or practices of other organizations, such as Facebook, Twitter, Instagram, YouTube, Pinterest, LinkedIn, Apple, Google, Microsoft, or any other app developer, app provider, social media platform, operating system provider, wireless service provider, or device manufacturer, including with respect to any Personal Information you disclose to other organizations through or in connection with our apps or social media pages.

## Jurisdiction and Cross-Border Transfer
We are located in the United States.  We may store and process your Personal Information in any country where we have facilities or in which we engage service providers.  By using the Services, you understand that your information will be transferred to countries outside of your country of residence, including the United States, which may have data protection rules that are different from those of your country.  

## Sensitive Information
Unless we request it, we ask that you not send us, and you not disclose, any sensitive Personal Information (e.g., Social Security numbers, information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, or criminal background) on or through the Services or otherwise to us.

## Third-Party Payment Service
The Services may provide functionality allowing you to make payments using a third-party payment service with which you have created your own account.  When you use such a service to make a payment to us, your Personal Information will be collected by such third party and not by us and will be subject to the third party’s privacy policy, rather than this Privacy Policy.  We have no control over, and are not responsible for, any such third party’s collection, use, or disclosure of your Personal Information.

## Updates to This Privacy Policy
The “Last Updated” legend at the top of this Privacy Policy indicates when this Privacy Policy was last revised.  Any changes will become effective when we post the revised Privacy Policy on the Services.  

## Contact Us
If you have any questions about this Privacy Policy, please contact us at contact@sharedot.com.`

const markdownStyles = `
body {
       background: black;
       margin: 0;
       padding: 0;
       min-height: 100vh;
     }

    .markdown-content {
      max-width: 65ch;
      margin: 0 auto;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    }
    
    .markdown-content h1 {
      font-size: 2.5rem;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 2rem;
      letter-spacing: -0.02em;
      text-align: left;
      color: white;
    }

    .markdown-content h2 {
      margin-top: 2.5em;
      margin-bottom: 1em;
      font-size: 1.25em;
      line-height: 1.4;
      color: white;
      font-weight: bold;
      text-align: left;
    }
    
    .markdown-content p {
      font-size: 1.125rem;
      line-height: 1.4;
      margin-bottom: 1rem;
      color: rgba(255, 255, 255, 0.9);
    }
    
    .markdown-content ul {
      margin: 2rem 0;
      padding-left: 1.5rem;
      line-height: 1.4;
      list-style-type: disc;
      text-align: left;
    }
    
    .markdown-content li {
      font-size: 1.125rem;
      line-height: 1.2;
      margin-bottom: 1rem;
      color: rgba(255, 255, 255, 0.9);
      padding-left: 0.5rem;
      text-align: left;
    }
    
    .markdown-content a {
        color: white;
        text-decoration: underline;
        cursor: pointer;
    }
  `;

  return (
    <Box className="min-h-screen" style={{ backgroundColor: 'black' }}>
      <style>{markdownStyles}</style>
      <Header />
      
      <Box style={{
        maxWidth: '800px',
        margin: '0 auto',
        padding: '120px 24px 0', // Reduced padding for mobile
      }}>
        <Box style={{ paddingTop: '96px' }}>
          <Text style={{
            fontSize: '18px',
            color: 'white',
            textAlign: 'center',
            marginBottom: '32px'
          }}>
            Last Updated: February 6, 2025
          </Text>
          <Text style={{
            fontSize: '48px',
            fontWeight: 500,
            textAlign: 'center',
            marginBottom: '96px',
            color: 'white'
          }}>
            Privacy Policy
          </Text>
          <Box className="markdown-content" style={{
            color: 'white',
            margin: '64px 0',
            textAlign: 'left'
          }}>
            <ReactMarkdown>{policyContent}</ReactMarkdown>
          </Box>
        </Box>
      </Box>
    </Box>
);

};

export default PrivacyPolicy;