import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Text } from '@chakra-ui/react';
import Header from './Header';

const AboutPage = () => {
  const aboutContent = `

## The Fastest Path to Information

ShareDot is transforming how we discover, consume, and benefit from information. We're building the first agentic inbox - a new paradigm for receiving relevant, personalized information to power your life.

## Our Mission

We're building the fastest path to relevant, useful information to make your life better.

## Information: A Survival Advantage

We are hunter gatherers of information. Better access to information improves the quality of our lives. But information, like entropy, is always increasing. Finding relevant information is hard work and we spend countless hours searching and curating information.

Building better tools to streamline information retrieval is at the core of ShareDot's mission - to find and surface the most relevant, useful information to you.

## The Two Traditional Paths to Information

Historically, there have been two primary ways we access information:

1. **Search** - Active information seeking based on specific needs (Google)
2. **Recommendation** - Passive discovery of content through algorithms (Netflix, TikTok, social feeds)

Both paths were revolutionized by the internet, but only one has evolved in the age of AI.

## How Large Language Models Changed Search

The search experience has been transformed by LLMs:

**Before**: Manual searching, clicking multiple links, reading and synthesizing information yourself, time-consuming research

**Now**: LLM chatbots like Perplexity, Claude, and ChatGPT provide instant, synthesized answers with direct citations - dramatically reducing the friction between questions and answers.

This is a major evolutionary step for one information path. But what about the second?

## The Problem with Current Recommendation Systems

Today's recommendation-based products face significant limitations:

- Social feeds prioritize engagement over value, making it difficult to find authentic, relevant content
- High noise, low signal - what if you could just get the best tidbits of Twitter without actually having to scroll through Twitter?
- Limited personalization beyond collaborative filtering ("people who liked X also liked Y") and no ability to just tell your feed what you want
- Reliance on user-generated content created by influencers for engagement, not for value, nor for your specific needs
- Design your feed and simply tell it what you want, how you want it, and when

## The Agentic Information Gap

Everyone is building AI agents - from productivity assistants to research tools. But where do these agents send information? Current interfaces aren't designed for continuous, agentic information flow:

- Chatbots use a query-response pattern unsuitable for ongoing information delivery
- No dedicated interface exists for proactive AI recommendations
- Users lack mechanisms to provide feedback that improves agent performance
- There's no browsable, skimmable interface for AI-curated content

**We need a new UI paradigm for agentic information.**

## Introducing the Agentic Inbox

ShareDot is creating the first true agentic inbox - a new interface designed specifically for AI-curated content:

### Key Features

- Continuous feed of AI-synthesized content based on your interests and needs
- User feedback mechanisms that help the system understand what's valuable to you
- Task creation for agents to fulfill specific information requests
- Seamless browsing experience designed for efficient information consumption

### The Benefits

- Shift from user-generated content to high-quality AI synthesis and curation
- Daily summaries customized to your specific interests and information needs
- Continuous learning from your interactions to deliver increasingly relevant content
- Higher signal-to-noise ratio than traditional social media
- True personalization based on your explicit preferences and implicit feedback

## Our Vision

ShareDot delivers the fastest path to relevant, useful information that improves your life.

Imagine waking up to a personalized summary of everything you want to know, tailored precisely to your life and interests. No more endless scrolling, no more searching through multiple sources - just the information that matters to you, delivered efficiently.

We're creating the information interface of the future - where AI works continuously to bring you the knowledge that enhances your decision-making, expands your understanding, and improves your life.

## Join Us

ShareDot is at the forefront of the next evolution in how humans interact with information. We're building more than just another app - we're creating a new paradigm for knowledge discovery and consumption in the age of AI.

Stay informed about our progress and be the first to experience the agentic inbox by signing up for updates on our website.
  `;

  const markdownStyles = `
    body {
      background: black;
      margin: 0;
      padding: 0;
      min-height: 100vh;
    }
    .markdown-content {
      max-width: 65ch;
      margin: 0 auto;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    }
    
    .markdown-content h1 {
      font-size: 2.5rem;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 2rem;
      letter-spacing: -0.02em;
      text-align: left;
      color: white;
    }
    .markdown-content h2 {
      margin-top: 2.5em;
      margin-bottom: 1em;
      font-size: 1.25em;
      line-height: 1.4;
      color: white;
      font-weight: bold;
      text-align: left;
    }
    
    .markdown-content h3 {
      margin-top: 1.5em;
      margin-bottom: 0.75em;
      font-size: 1.1em;
      line-height: 1.4;
      color: white;
      font-weight: bold;
      text-align: left;
    }
    
    .markdown-content p {
      font-size: 1.125rem;
      line-height: 1.4;
      margin-bottom: 1rem;
      color: rgba(255, 255, 255, 0.9);
    }
    
    .markdown-content ul {
      margin: 2rem 0;
      padding-left: 1.5rem;
      line-height: 1.4;
      list-style-type: disc;
      text-align: left;
    }
    
    .markdown-content li {
      font-size: 1.125rem;
      line-height: 1.2;
      margin-bottom: 1rem;
      color: rgba(255, 255, 255, 0.9);
      padding-left: 0.5rem;
      text-align: left;
    }
    
    .markdown-content a {
      color: white;
      text-decoration: underline;
      cursor: pointer;
    }
    
    .markdown-content strong {
      color: white;
      font-weight: 600;
    }
    
    .markdown-content ol {
      margin: 2rem 0;
      padding-left: 1.5rem;
      line-height: 1.4;
      list-style-type: decimal;
      text-align: left;
    }
    
    .markdown-content ol li {
      font-size: 1.125rem;
      line-height: 1.2;
      margin-bottom: 1rem;
      color: rgba(255, 255, 255, 0.9);
      padding-left: 0.5rem;
      text-align: left;
    }
  `;

  return (
    <Box className="min-h-screen" style={{ backgroundColor: 'black' }}>
      <style>{markdownStyles}</style>
      <Header />
      
      <Box style={{
        maxWidth: '800px',
        margin: '0 auto',
        padding: '120px 24px 0', // Reduced padding for mobile
      }}>
        <Box style={{ paddingTop: '96px' }}>
          <Text style={{
            fontSize: '18px',
            color: 'white',
            textAlign: 'center',
            marginBottom: '32px'
          }}>
            Last Updated: February 24, 2025
          </Text>
          <Text style={{
            fontSize: '48px',
            fontWeight: 500,
            textAlign: 'center',
            marginBottom: '96px',
            color: 'white'
          }}>
            About Us
          </Text>
          <Box className="markdown-content" style={{
            color: 'white',
            margin: '64px 0',
            textAlign: 'left'
          }}>
            <ReactMarkdown>{aboutContent}</ReactMarkdown>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutPage;