import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Text } from '@chakra-ui/react';
import Header from './Header';

const TermsofUse = () => {
  const policyContent = `

These Terms of Use (“Terms”) apply to your access to and use of our website, applications, and other services (collectively, our “Services”) provided by ShareDot, Inc (“Company”, “us”, “our”, and “we”).

By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms in their entirety, do not use our Services.

We may make changes to these Terms from time to time. If we make changes, we will provide you with notice of such changes, such as by sending an email providing a link to the updated Terms. Unless we say otherwise in our notice, the amended Terms will be effective immediately, and your continued use of our Services after we provide such notice will confirm your acceptance of the changes. If you do not agree to the amended Terms, you must stop using our Services.
If you have any questions about these Terms or our Services, please contact us at contact@sharedot.com.

## Age Requirements
You must be at least 13 years old or the minimum age required in your country to consent to use the Services. If you are under 18 you must have your parent or legal guardian’s permission to use the Services. 

## Privacy
For information about how we collect, use, share or otherwise process information about you, please see our [Privacy Policy](https://www.sharedot.com/privacypolicy).

## Eligibility
By using our Services, you represent and warrant that you have the legal right and ability to enter into the agreement to use our Services in accordance with these Terms of Use. If you use our Services on behalf of another person or entity, (a) all references to “you” throughout these Terms will include that person or entity, (b) you represent that you are authorized to accept these Terms on that person’s or entity’s behalf, and (c) in the event you or the person or entity violates these Terms, the person or entity agrees to be responsible to us.

You may not use the Services if you are located in, or a citizen or resident of, any country, territory or other jurisdiction that is embargoed by the United States or where your use of the Services would be illegal or otherwise violate any applicable law. You represent and warrant that you are not a citizen or resident of any such jurisdiction and that you will not use any Services while located in any such jurisdiction. You also may not use the Services if you are located in, or a citizen or resident of, any other jurisdiction where we have determined, at our discretion, to prohibit use of the Services, as set forth in these Terms. We may implement controls to restrict access to the Services from any such jurisdiction. You will comply with this paragraph even if our methods to prevent use of the Services are not effective or can be bypassed.

## User Accounts and Account Security
You may need to register for an account to access some or all of our Services. If you register for an account, you must provide accurate account information and promptly update this information if it changes. You also must maintain the security of your account and promptly notify us if you discover or suspect that someone has accessed your account without your permission. If you permit others to use your account credentials, you are responsible for the activities of such users that occur in connection with your account. We reserve the right to reclaim usernames, including on behalf of businesses or individuals that hold legal claim, including trademark rights, in those usernames.

# User Content

## Definition and Ownership
Our Services may allow you and other users to create, post, store, and share content, including notes, tasks, internet links (URLs), and photos (collectively, “User Content”). Except for the license you grant below, you retain all rights in your User Content, as between you and us.

## License
You grant us and our subsidiaries and affiliates a perpetual, irrevocable, nonexclusive, royalty-free, worldwide, fully paid, and sublicensable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, publicly perform, and display your User Content and any name, username, or likeness embodied in or provided in connection with your User Content, and in all media formats and channels now known or later developed without compensation to you. Depending on your account settings, when you post or otherwise share User Content on or through our Services, you understand that your User Content and any associated information (such as your username or profile photo) may be visible to others.

## Your Obligations
You may not create, post, store, or share any User Content that violates these Terms or for which you do not have all the rights necessary to grant us the license described above. You represent and warrant that your User Content, and our use of such content as permitted by these Terms, will not violate any rights of or cause injury to any person or entity. Although we have no obligation to screen, edit, or monitor User Content, we may delete or remove User Content at any time and for any reason with or without notice. 

## Waiver of Responsibility and Liability
We take no responsibility and assume no liability for any User Content posted, stored, uploaded, accessed, or downloaded by you or any third party, or for any loss or damage thereto, incomplete or inaccurate User Content, or for any user conduct, nor are we liable for any mistakes, defamation, slander, libel, omissions, falsehoods, obscenity, pornography, or profanity you may encounter. Enforcement of the User Content or conduct rules set forth in these Terms is solely at our discretion, and failure to enforce such rules in some instances does not constitute a waiver of our right to enforce such rules in other instances. In addition, these rules do not create any private right of action on the part of any third party or any reasonable expectation that our Services will not contain any content that is prohibited by such rules. As a provider of interactive services, we are not liable for any statements, representations, or User Content provided by our users in any public forum or other area. Although we have no obligation to screen, edit, or monitor any User Content, we reserve the right, and have absolute discretion, to remove, screen, or edit any User Content posted or stored on our Services at any time and for any reason without notice.


## Prohibited Conduct and Content
You will not violate any applicable law, contract, intellectual property right, or other third-party right or commit a tort, and you are solely responsible for your conduct while using our Services. In addition, you will not:
- Engage in any harassing, threatening, intimidating, predatory, or stalking conduct; 
- Use or attempt to use another user’s account without authorization from that user and us; 
- Impersonate or post on behalf of any person or entity or otherwise misrepresent your affiliation with a person or entity (which, for clarity, doesn’t include bona fide satire); 
- Sell, resell, or commercially use our Services; 
- Copy, reproduce, distribute, publicly perform, or publicly display all or portions of our Services, except as expressly permitted by us or our licensors; 
- Modify our Services, remove any proprietary rights, notices, or markings, or otherwise make any derivative works based upon our Services; 
- Use our Services other than for their intended purpose and in any manner that could interfere with, disrupt, negatively affect, or inhibit other users from fully enjoying our Services or that could damage, disable, overburden, or impair the functioning of our Services in any manner; 
- Reverse engineer any aspect of our Services or do anything that might discover source code or bypass or circumvent measures employed to prevent or limit access to any part of our Services; 
- Attempt to circumvent any content-filtering techniques we employ or attempt to access any feature or area of our Services that you are not authorized to access; Use any data mining, robots, or similar data gathering or extraction methods designed to scrape or extract data from our Services; 
- Develop or use any applications that interact with our Services without our prior written consent; 
- Send, distribute, or post spam, unsolicited or bulk commercial electronic communications, chain letters, or pyramid schemes; 
- Bypass or ignore instructions contained in our robots.txt file; or
- Use our Services for any illegal or unauthorized purpose, or engage in, encourage, or promote any activity that violates these Terms.
- You may also post or otherwise share only User Content that is nonconfidential and that you have all necessary rights to disclose. You may not create, post, store, or share any User Content that:
- Is unlawful, libelous, defamatory, obscene, pornographic, indecent, lewd, suggestive, harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory, or fraudulent; 
- Would constitute, encourage, or provide instructions for a criminal offense, violate the rights of any party, or otherwise create liability, or violate any local, state, national, or international law; 
- May infringe any patent, trademark, trade secret, copyright, or other intellectual or proprietary right of any party; 
- Contains or depicts any statements, remarks, or claims that do not reflect your honest views and experiences or which impersonates or misrepresents your affiliation with any person or entity (which, for clarity, doesn’t include bona fide satire); 
- Contains any unsolicited promotions, political campaigning, advertising, or solicitations; 
- Contains any private or personal information of a third party without such third party’s consent; 
- Contains any viruses, corrupted data, or other harmful, disruptive, or destructive files or content; or
- In our sole judgment, is objectionable, restricts or inhibits any other person from using or enjoying our Services, or may expose us or others to any harm or liability of any type.

Enforcement of this Section 5 is solely at our discretion, and failure to enforce this section in some instances does not constitute a waiver of our right to enforce it in other instances. In addition, this Section 5 does not create any private right of action on the part of any third party or any reasonable expectation that the Services will not contain any content that is prohibited by such rules.

## Ownership; Limited License
Our Services, including the text, graphics, images, photographs, videos, illustrations, and other content contained therein, are owned by us or our licensors and are protected under both United States and foreign laws. Except as explicitly stated in these Terms, all rights in and to our Services are reserved by us or our licensors. Subject to your compliance with these Terms, you are hereby granted a limited, nonexclusive, nontransferable, non-sublicensable, revocable license to access and use our Services for your own personal, noncommercial use. Any use of our Services other than as specifically authorized in these Terms, without our prior written permission, is strictly prohibited, will terminate this license, and violate our intellectual property rights.

## Trademarks
ShareDot and our logos, our product or service names, our slogans, and the look and feel of our Services are our trademarks and may not be copied, imitated, or used, in whole or in part, without our prior written permission (except for any nominative “fair use” as permitted by law). All other trademarks, registered trademarks, product names and company names or logos mentioned on our Services are the property of their respective owners. Reference to any products, services, processes, or other information by trade name, trademark, manufacturer, supplier, or otherwise does not constitute or imply endorsement, sponsorship, or recommendation by us.

## Feedback
You may voluntarily post, submit, or otherwise communicate to us any questions, comments, suggestions, ideas, original or creative materials, or other information about us or our Services (collectively, “Feedback”). For clarity, Feedback does not constitute User Content. You understand that we may use such Feedback for any purpose, commercial or otherwise, without acknowledgment or compensation to you, including, without limitation, to develop, copy, publish, or improve the Feedback in our sole discretion. In addition, you waive any so-called “moral rights” or rights of privacy or publicity in the Feedback. You understand that we may treat Feedback as nonconfidential.

## Repeat Infringer Policy; Copyright Complaints
In accordance with the Digital Millennium Copyright Act and other applicable law, we have adopted a policy of terminating, in appropriate circumstances, the accounts of users who repeatedly infringe the intellectual property rights of others. If you believe that anything on our Services infringes any copyright that you own or control, you may notify us at contact@sharedot.com.

Please see 17 U.S.C. § 512(c)(3) for the requirements of a proper notification. Also, please note that if you knowingly misrepresent that any activity or material on our Services is infringing, you may be liable to us for certain costs and damages. Note also that certain types of uses of copyrighted material for purposes such as criticism, comment, news reporting, teaching, scholarship, or research, may constitute “fair use” under the law and would be excused from infringement of a copyright. 

## Third-Party Content
We may provide information about third-party products, services, activities, or events, or we may allow third parties to make their content and information available on or through our Services (collectively, “Third-Party Content”). We provide Third-Party Content as a service to those interested in such content. Your dealings or correspondence with third parties and your use of or interaction with any Third-Party Content are solely between you and the third party. We do not control or endorse, and make no representations or warranties regarding, any Third-Party Content, and your access to and use of such Third-Party Content is at your own risk.

## Disclaimers
Your use of our Services is at your sole risk. Except as otherwise provided in a writing by us, our Services and any content therein are provided ”as is” and ”as available” without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. In addition, we do not represent or warrant that our Services are accurate, complete, reliable, current, or error-free. While we attempt to make your use of our Services and any content therein safe, we cannot and do not represent or warrant that our Services or servers are free of viruses or other harmful components. You assume the entire risk as to the quality and performance of our Services.

## Limitation of Liability
To the fullest extent permitted by applicable law, we and the other Company Parties will not be liable to you under any theory of liability—whether based in contract, tort, negligence, strict liability, warranty, or otherwise—for any indirect, consequential, exemplary, incidental, punitive, or special damages or lost profits, even if we or the other Company Parties have been advised of the possibility of such damages. 

The total liability of us and the other Company Parties for any claim arising out of or relating to these Terms or our Services, regardless of the form of the action, is limited to the amount paid by you to use our Services. 
The limitations set forth in this Section 12 will not limit or exclude liability for the gross negligence, fraud, or intentional misconduct of us or the other Company Parties or for any other matters in which liability cannot be excluded or limited under applicable law. Additionally, some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitations or exclusions may not apply to you.

## User Content Releases
Except where prohibited by applicable law, by submitting User Content to us, you are waiving and agreeing not to assert any copyrights or “moral” rights or claim resulting from our alteration of the User Content. You hereby release and discharge us and our agents, employees, and assigns, and anyone acting under their authorization, from any claims that use of any User Content as authorized herein violates any of your rights, including without limitation any rights of publicity or privacy and intellectual property rights. You understand that you will not be entitled to any additional compensation for the permission and release granted herein or for the use of any of User Content as authorized herein.

## Transfer and Processing Data
In order for us to provide our Services, you agree that we may process, transfer, and store information about you in the United States and other countries, where you may not have the same rights and protections as you do under local law.

## Governing Law and Venue
Any dispute arising from these Terms and your use of our Services will be governed by and construed and enforced in accordance with the laws of the State of California, except to the extent preempted by U.S. federal law, without regard to conflict of law rules or principles (whether of the State of California or any other jurisdiction) that would cause the application of the laws of any other jurisdiction. Any dispute between the parties that cannot be heard in small claims court will be resolved in the state or federal courts of California and the United States, respectively, sitting in San Francisco County, California.

## Modifying and Terminating our Services
We reserve the right to modify our Services or to suspend or stop providing all or portions of our Services at any time. You also have the right to stop using our Services at any time. We are not responsible for any loss or harm related to your inability to access or use our Services.

## Severability
If any provision or part of a provision of these Terms is unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms and does not affect the validity and enforceability of any remaining provisions.

## Miscellaneous
These Terms, including any terms and conditions incorporated in these Terms, is the entire agreement between you and Company relating to its subject matter, and supersedes all other agreements or understandings between you and Company relating to its subject matter. Notwithstanding any other provisions of these Terms, Sections 1, 3, 4, 6 (except for the license and rights granted), 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, and this 18 survive any expiration or termination of these terms. Our failure to exercise or enforce any right or provision of these Terms will not operate as a waiver of such right or provision. The section titles in these Terms are for convenience only and have no legal or contractual effect. Except as otherwise provided herein, these Terms are intended solely for the benefit of the parties and are not intended to confer third-party beneficiary rights upon any other person or entity. You agree that communications and transactions between us may be conducted electronically. These Terms may not be transferred, assigned or delegated by you, by operation of law or otherwise, without our prior written consent, and any attempted transfer, assignment or delegation without such consent will be void and without effect. We may freely transfer, assign or delegate these Terms or our Services, in whole or in part, without your prior written consent.

## Copyright/Trademark Information  
Copyright © 2025 ShareDot, Inc. All rights reserved. All trademarks, logos and service marks (“Marks”) displayed on the Site are our property or the property of other third parties. You are not permitted to use these Marks without our prior written consent or the consent of such third party which may own the Marks.

## Contact Information
ShareDot, Inc \n
*Address*:
166 Geary St STE 1500 #2498, San Francisco, California 94108\n
*Telephone*:
3104045324\n
*Email*:
contact@sharedot.com`; // Full policy content here

const markdownStyles = `
body {
       background: black;
       margin: 0;
       padding: 0;
       min-height: 100vh;
     }

    .markdown-content {
      max-width: 65ch;
      margin: 0 auto;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    }
    
    .markdown-content h1 {
      font-size: 2rem;
      font-weight: 500;
      line-height: 1.2;
      margin-top: 5rem;
      letter-spacing: -0.02em;
      text-align: left;
      color: white;
    }

    .markdown-content h2 {
      margin-top: 2.5em;
      margin-bottom: 1em;
      font-size: 1.25em;
      line-height: 1.4;
      color: white;
      font-weight: bold;
      text-align: left;
    }
    
    .markdown-content p {
      font-size: 1.125rem;
      line-height: 1.4;
      margin-bottom: 1rem;
      color: rgba(255, 255, 255, 0.9);
    }
    
    .markdown-content ul {
      margin: 2rem 0;
      padding-left: 1.5rem;
      line-height: 1.4;
      list-style-type: disc;
      text-align: left;
    }
    
    .markdown-content li {
      font-size: 1.125rem;
      line-height: 1.2;
      margin-bottom: 1rem;
      color: rgba(255, 255, 255, 0.9);
      padding-left: 0.5rem;
      text-align: left;
    }
    
    .markdown-content a {
        color: white;
        text-decoration: underline;
        cursor: pointer;
    }
  `;

  return (
    <Box className="min-h-screen" style={{ backgroundColor: 'black' }}>
      <style>{markdownStyles}</style>
      <Header />
      
      <Box style={{
        maxWidth: '800px',
        margin: '0 auto',
        padding: '120px 24px 0', // Reduced side padding for mobile
        '@media (min-width: 768px)': { // Media query for desktop
          padding: '120px 64px 0'
        }
      }}>
        <Box style={{ paddingTop: '96px' }}>
          <Text style={{
            fontSize: '18px',
            color: 'white',
            textAlign: 'center',
            marginBottom: '32px'
          }}>
            Last Updated: February 6, 2025
          </Text>
          <Text style={{
            fontSize: '48px',
            fontWeight: 500,
            textAlign: 'center',
            marginBottom: '96px',
            color: 'white'
          }}>
            Terms of Use
          </Text>
          <Box className="markdown-content" style={{
            color: 'white',
            margin: '64px 0',
            textAlign: 'left'
          }}>
            <ReactMarkdown>{policyContent}</ReactMarkdown>
          </Box>
        </Box>
      </Box>
    </Box>
);

};

export default TermsofUse;