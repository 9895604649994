import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Text,
  Flex,
  Textarea,
  Button,
  VStack,
  Input,
  useToast,
  HStack,
  IconButton,
  useDisclosure,
  useMediaQuery,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spacer,
  Progress,
} from '@chakra-ui/react';
import { CalendarIcon, AddIcon } from '@chakra-ui/icons';
import withFirebaseAuth from '../utils/with_firebase_auth';
import Markdown from 'react-markdown';
import Cookies from 'js-cookie';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../auth/AuthContext";
import { NotesLoginPrompt } from './LoginPrompts';


const shimmerStyles = `
.shimmer-container {
  position: relative;
  overflow: hidden;
  background: #1a1a1a;
}

.shimmer-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
`;

// Loading Screen Component
const LoadingScreen = () => {
  return (
    <Box h="100vh" bg="black">
      {/* Main content area */}
      <Box 
        display="flex" 
        h="100vh" 
        w="100%" 
      >
        {/* Left column */}
        <Box 
          w="50%" 
          h="100%" 
          borderRight="1px" 
          borderColor="whiteAlpha.200"
          display="flex"
          flexDirection="column"
        >
          {/* Message area shimmer */}
          <Box 
            flex="1" 
            p={4}
            className="shimmer-container"
          >
            <Box className="shimmer-effect" />
          </Box>
          
          {/* Input area shimmer */}
          <Box p={4}>
            <Box 
              h="50px" 
              borderRadius="24px"
              className="shimmer-container"
            >
              <Box className="shimmer-effect" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const ShimmerLoadingCard = () => (
  <Box
    bg="gray.900"
    borderRadius="md"
    overflow="hidden"
    mb={2}
    className="shimmer-container"
    h="80px"
    w="100%"
  >
    <Box className="shimmer-effect" />
  </Box>
);

const NoteCard = ({ note, isSelected, onClick }) => {
  const truncateText = (text, limit) => {
    if (!text) return '';
    if (text.length <= limit) return text;
    return text.slice(0, limit) + '...';
  };

  if (note.noteId === 'add') {
    // Redesigned Add Note card with selected state
    return (
      <Box
        borderWidth="1px"
        borderStyle={isSelected ? 'solid' : 'dashed'}
        borderColor={isSelected ? 'blue.500' : 'gray.500'}
        bg={isSelected ? 'blue.900' : 'black'}
        borderRadius="10px"
        cursor="pointer"
        p={4}
        width="100%"
        mb={{ base: 2, md: 3 }} // Reduced margin bottom for mobile
        onClick={onClick}
        textAlign="center"
        color={isSelected ? 'white' : 'gray.500'}
      >
        <Flex direction="column" alignItems="center" justifyContent="center">
          <Text fontSize="lg">+ Add Note</Text>
        </Flex>
      </Box>
    );
  }

  const renderNoteContent = () => {
    if (note.noteType === 'ONBOARDING_QUESTION') {
      const answer = note.content.split('My Answer: ')[1];
      return truncateText(answer, 150);
    } else if (note.noteType === 'FEEDBACK') {
      const feedback = note.content.split('My Feedback: ')[1];
      return truncateText(feedback, 150);
    } else {
      return truncateText(note.content, 40);
    }
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="10px"
      overflow="hidden"
      cursor="pointer"
      borderColor={isSelected ? 'blue.500' : 'white'}
      bg={isSelected ? 'blue.900' : 'black'}
      p={4}
      width="100%"
      mb={3}
      onClick={onClick}
    >
      <Text
        fontSize={{ base: 'md', md: 'lg' }}
        fontWeight="700"
        color="white"
        mb={2}
        textAlign="left"
      >
        {renderNoteContent()}
      </Text>

      {note.noteId !== 'add' && (
        <Text
          fontSize={{ base: 'sm', md: 'sm' }}
          color="whiteAlpha.600"
          textAlign="left"
        >
          {new Date(note.updatedAt).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })}
        </Text>
      )}
    </Box>
  );
};

const NotesView = () => {
  const { authState, isLoading } = useAuth();
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedNote, setSelectedNote] = useState(null);
  const [noteContent, setNoteContent] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const userId = Cookies.get('userId');
  const textareaRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('descending'); // default to descending
  const toast = useToast();
  const [hasNoteChanged, setHasNoteChanged] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();

  // Define renderLoginPrompt inside the component
  const renderLoginPrompt = () => (
    <Flex
      direction="column"
      align="center"
      justify="center"
      h="full"
      p={4}
      textAlign="center"
      color="white"
    >
      <Text fontSize="xl" mb={4}>
        Sign in to add notes
      </Text>
      <Button
        onClick={() => navigate('/login')}
        bg="white"
        color="black"
        size="lg"
        _hover={{
          bg: "gray.100"
        }}
      >
        Sign In
      </Button>
    </Flex>
  );

  useEffect(() => {
    const fetchNotes = async () => {
      if (!userId) {
        console.error('User ID not found');
        setLoading(false); // Make sure to set loading to false if there's no userId
        return;
      }
  
      try {
        setLoading(true);
        const fetchWithAuth = withFirebaseAuth(fetch);
        const response = await fetchWithAuth(`/api/notes`);
  
        if (response.ok) {
          const data = await response.json();
          setNotes(data);
          if (!isMobile) {
            handleAddNoteClick();
          }
        } else {
          console.error('Failed to fetch notes');
        }
      } catch (error) {
        console.error('Error fetching notes:', error);
      } finally {
        // Ensure loading is set to false after a small delay to prevent flash
        setTimeout(() => {
          setLoading(false);
        }, 100);
      }
    };
  
    fetchNotes();
  }, [userId, isMobile]);

  useEffect(() => {
    if (selectedNote) {
      setNoteContent(selectedNote.content || '');
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
      setHasNoteChanged(false);
    } else {
      setNoteContent('');
    }
  }, [selectedNote]);

  const handleNoteContentChange = (e) => {
    const newValue = e.target.value.slice(0, 2000);
    setNoteContent(newValue);
    if (selectedNote && selectedNote.content !== newValue) {
      setHasNoteChanged(true);
    } else {
      setHasNoteChanged(false);
    }
  };

  const handleNoteClick = (note) => {
    setSelectedNote(note);
    if (isMobile) {
      onOpen();
    }
  };

  const handleAddNoteClick = () => {
    const newNote = {
      noteId: 'new',
      content: '',
      createdAt: new Date(),
      updatedAt: new Date(),
      status: 'NEW',
      noteType: 'NOTE',
    };
    setSelectedNote(newNote);
    setNoteContent('');
    if (isMobile) {
      onOpen();
    }
  };

  const handleSaveNote = async () => {
    if (!selectedNote) return;
    setIsSaving(true);

    if (!userId) {
      console.error('User ID not found. Please log in again.');
      setIsSaving(false);
      return;
    }

    const newNoteData = {
      userId: userId,
      relatedAskId: null,
      content: noteContent,
      embeddingModel: null,
      tokenCount: null,
      tokenCost: null,
      status: 'ENABLED',
      isArchived: false,
      createDate: new Date().toISOString(),
      noteType: 'NOTE',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    try {
      const fetchWithAuth = withFirebaseAuth(fetch);
      const response = await fetchWithAuth(`/api/notes`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newNoteData),
      });

      if (response.ok) {
        const responseData = await response.json();

        const addedNote = { ...newNoteData, noteId: responseData.noteId };

        setNotes((prevNotes) => [addedNote, ...prevNotes]);

        setSelectedNote(addedNote);
        setHasNoteChanged(false);

        toast({
          title: 'Note Added',
          description: 'Your note has been successfully added.',
          status: 'success',
          duration: 1500,
          isClosable: true,
          render: () => (
            <Box color="white" p={3} bg="#1C1C1C">
              <Text fontWeight="bold">Note Added</Text>
              <Text>Your note has been successfully added.</Text>
            </Box>
          ),
        });

        if (isMobile) {
          onClose();
        }
      } else {
        console.error('Failed to add note:', response.statusText);
      }
    } catch (error) {
      console.error('Error adding note:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleUpdateNote = async () => {
    if (!selectedNote) return;
    setIsSaving(true);

    try {
      const fetchWithAuth = withFirebaseAuth(fetch);
      const response = await fetchWithAuth(
        `/api/notes/${selectedNote.noteId}`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ content: noteContent }),
        }
      );

      if (response.ok) {
        toast({
          title: 'Note Updated',
          description: 'Your note has been updated successfully.',
          status: 'success',
          duration: 1500,
          isClosable: true,
          render: () => (
            <Box color="white" p={3} bg="#1C1C1C">
              <Text fontWeight="bold">Note Updated</Text>
              <Text>Your note has been updated successfully.</Text>
            </Box>
          ),
        });

        setSelectedNote((prevNote) => {
          return { ...prevNote, content: noteContent };
        });

        setNotes((prevNotes) => {
          return prevNotes.map((note) =>
            note.noteId === selectedNote.noteId
              ? { ...note, content: noteContent }
              : note
          );
        });
        setHasNoteChanged(false);

        if (isMobile) {
          onClose();
        }
      } else {
        console.error('Failed to update note:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating note:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteNote = async () => {
    if (!selectedNote) return;
    setIsDeleting(true);

    try {
      const fetchWithAuth = withFirebaseAuth(fetch);
      const response = await fetchWithAuth(
        `/api/notes/${selectedNote.noteId}/disable`,
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ status: 'DISABLED' }),
        }
      );

      if (response.ok) {
        setNotes((prevNotes) =>
          prevNotes.filter((note) => note.noteId !== selectedNote.noteId)
        );
        setSelectedNote(null);
        setNoteContent('');

        toast({
          title: 'Note Deleted',
          description: 'The note has been deleted.',
          status: 'success',
          duration: 2000,
          isClosable: true,
          render: () => (
            <Box color="white" p={3} bg="#1C1C1C">
              <Text fontWeight="bold">Note Deleted</Text>
              <Text>The note has been deleted.</Text>
            </Box>
          ),
        });

        if (isMobile) {
          onClose();
        }
      } else {
        console.error('Failed to delete note:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting note:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) =>
      prevOrder === 'ascending' ? 'descending' : 'ascending'
    );
  };

  const filteredNotes = notes
    .filter(
      (note) =>
        note.status === 'ENABLED' &&
        note.content &&
        note.content.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortOrder === 'ascending') {
        return new Date(a.createdAt) - new Date(b.createdAt);
      } else {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
    });

    // Return statement with conditionals
  if (isLoading) {
    return <LoadingScreen />;
  }

  // if (!authState.userId) {
  //   return (
  //     <Box h="100vh" bg="black">
  //       <Header />
  //       <Flex h="calc(100vh - 64px)" pt="64px">
  //         <Box w="100%" h="full" borderRight="1px" borderColor="whiteAlpha.200">
  //           {renderLoginPrompt()}
  //         </Box>
  //       </Flex>
  //     </Box>
  //   );
  // }

  if (!authState.userId) {
    return (
      <Box h="100vh" bg="black">
          <Box w="100%" h="full" borderRight="1px" borderColor="whiteAlpha.200">
            <NotesLoginPrompt onSignInClick={() => navigate('/login')} />
          </Box>
      </Box>
    );
  }


    return (
      <Box 
        h="full" 
        display="flex" 
        flexDirection="column" 
        bg="black" 
        minH="100vh"
        position="relative"
      >
        <Header />
        <style>{shimmerStyles}</style>
  
        {(isSaving || isDeleting) && (
        <Progress
          size="xs"
          isIndeterminate
          sx={{
            '&': {
              backgroundColor: '#1C1C1C',
            },
            '& > div': {
              backgroundColor: '#FF0050',
            },
          }}
          position="fixed"
          top="0"
          left="0"
          right="0"
          zIndex="1500"
        />
      )}

{isMobile ? (
        // Mobile View
        <Box p={4}>
          {/* Search Bar and Sort Button */}
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={3}
          >
            <Input
              type="text"
              placeholder="Search your notes"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              flex={1}
              marginRight="10px"
              backgroundColor="#303133"
              color="white"
              fontSize="1rem"
              outline="none"
              borderRadius="full"
              border="none"
            />
            <Button
              onClick={toggleSortOrder}
              backgroundColor="#303133"
              color="white"
              height="46px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              _hover={{
                backgroundColor: '#303133',
                color: 'white',
              }}
            >
              <CalendarIcon marginRight="5px" />
              {sortOrder === 'ascending' ? '↑' : '↓'}
            </Button>
          </Flex>

          {/* Notes List */}
          <VStack spacing={2} align="stretch" flex="1" mb="80px"> {/* Added bottom margin for floating button */}
            {loading ? (
              [...Array(5)].map((_, index) => (
                <ShimmerLoadingCard key={index} />
              ))
            ) : filteredNotes.length === 0 ? (
              <Text color="whiteAlpha.600" textAlign="center">
                No notes found
              </Text>
            ) : (
              filteredNotes.map((note) => (
                <NoteCard
                  key={note.noteId}
                  note={note}
                  isSelected={selectedNote && selectedNote.noteId === note.noteId}
                  onClick={() => handleNoteClick(note)}
                />
              ))
            )}
          </VStack>

          {/* Floating Add Note Button */}
          <IconButton
            icon={<AddIcon />}
            colorScheme="whiteAlpha"
            aria-label="Add Note"
            position="fixed"
            bottom="20px"
            right="20px"
            borderRadius="full"
            boxSize="60px"
            onClick={handleAddNoteClick}
            zIndex="1000"
            bg="white"
            color="black"
            _hover={{ bg: 'gray.300' }}
          />

          {/* Add/Edit Note Modal */}
          <Modal isOpen={isOpen} onClose={onClose} size="full">
            <ModalOverlay />
            <ModalContent bg="black">
              <ModalHeader color="white">
                {selectedNote?.status === 'NEW' ? 'Add Note' : 'Edit Note'}
              </ModalHeader>

              <ModalBody p={4} display="flex" flexDirection="column">
                <Box flex="1" minH="0" display="flex" flexDirection="column">
                  <Textarea
                    ref={textareaRef}
                    value={noteContent}
                    onChange={handleNoteContentChange}
                    fontSize="lg"
                    color="white"
                    bg="black"
                    border="none"
                    borderRadius="10px"
                    resize="none"
                    flex="1"
                    _focus={{
                      boxShadow: 'none',
                      borderColor: 'whiteAlpha.300',
                    }}
                    maxLength={2000}
                    placeholder="Start typing your note..."
                  />
                  
                  {/* Character counter and buttons moved up */}
                  <Flex justifyContent="flex-end" mt={2} mb={2}>
                    <Text color="white" fontSize="sm">
                      {`${noteContent.length}/2000`}
                    </Text>
                  </Flex>
                  
                  <Flex mt={2}>
                    <Button
                      bg="white"
                      color="black"
                      isLoading={isSaving}
                      onClick={selectedNote?.status === 'NEW' ? handleSaveNote : handleUpdateNote}
                      mr={2}
                      disabled={isSaving || !hasNoteChanged || noteContent.trim() === ''}
                      _hover={{ bg: 'gray.300' }}
                    >
                      Save Note
                    </Button>
                    
                    {selectedNote?.status !== 'NEW' && (
                      <Button
                        variant="outline"
                        borderColor="white"
                        color="white"
                        isLoading={isDeleting}
                        onClick={handleDeleteNote}
                        mr={2}
                        _hover={{ bg: 'whiteAlpha.200' }}
                      >
                        Delete Note
                      </Button>
                    )}
                    <Spacer />
                    <Button variant="ghost" onClick={onClose} color="white">
                      Cancel
                    </Button>
                  </Flex>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      ) : (
        // Desktop View
        <Flex 
        h="100vh"
        w="full"
        overflow="hidden"
            >
            {/* Left Column: Note Content */}
            <Box
              w="70%" // Adjusted width
              h="full"
              mr="0"
              bg="black"
              display="flex"
              flexDirection="column"
              p={4}
            >
            {selectedNote ? (
                <>
                  {(selectedNote.noteType === 'NOTE' ||
                    selectedNote.status === 'NEW') && (
                    <>
                      <Box 
                        flex="none"  // Changed from flex="1" to flex="none"
                        mb={2}
                      >
                      <Textarea
                        ref={textareaRef}
                        value={noteContent}
                        onChange={handleNoteContentChange}
                        fontSize="lg"
                        color="white"
                        bg="blackAlpha.800"
                        borderColor="whiteAlpha.300"
                        borderRadius="10px"
                        resize="none"
                        h="60vh"
                        _focus={{
                          boxShadow: 'none',
                          borderColor: 'whiteAlpha.300',
                        }}
                        maxLength={2000}
                        placeholder="Start typing your note..."
                      />
                    </Box>
                    {/* Character Counter below the Textarea */}
                    <VStack spacing={2} align="stretch"></VStack>
                    <Flex justifyContent="flex-end">
                      <Text color="white" fontSize="sm">
                        {`${noteContent.length}/2000`}
                      </Text>
                    </Flex>
                  </>
                )}
                {selectedNote.noteType !== 'NOTE' &&
                  selectedNote.status !== 'NEW' && (
                    <Box p={4}>
                      <Markdown>{selectedNote.content}</Markdown>
                    </Box>
                  )}
                {/* Save and Delete buttons underneath input box */}
                <Flex>  {/* Added margin-bottom */}
                  <Button
                    bg="white"
                    color="black"
                    isLoading={isSaving}
                    onClick={
                      selectedNote.status === 'NEW'
                        ? handleSaveNote
                        : handleUpdateNote
                    }
                    mr={2}
                    disabled={
                      isSaving || !hasNoteChanged || noteContent.trim() === ''
                    }
                    _hover={{ bg: 'gray.300' }}
                  >
                    Save Note
                  </Button>
                  {selectedNote.status !== 'NEW' && (
                    <Button
                      variant="outline"
                      borderColor="white"
                      color="white"
                      isLoading={isDeleting}
                      onClick={handleDeleteNote}
                      _hover={{ bg: 'whiteAlpha.200' }}
                    >
                      Delete Note
                    </Button>
                  )}
                </Flex>
              </>
            ) : (
              <Flex h="full" alignItems="center" justifyContent="center">
                <Text color="whiteAlpha.600">
                  Select a note to view or edit
                </Text>
              </Flex>
            )}
          </Box>

          {/* Right Column: Add Note, Search Bar, and Notes List */}
          <Box
            w="30%" // Adjusted width
            bg="black"
            h="full"
            // borderWidth="1px"
            // borderColor="white"
            borderRadius="10px"
            // borderRight="0" // Remove right border
            // borderTopRightRadius="0" // Remove top right radius
            // borderBottomRightRadius="0" // Remove bottom right radius
            p="4"
            pr="4" // Ensure consistent padding on the right
            overflowY="auto"
            display="flex"
            flexDirection="column"
          >
            {/* Add Note Card */}
            <Box mb={4}>
              <NoteCard
                note={{ noteId: 'add' }}
                isSelected={selectedNote && selectedNote.status === 'NEW'}
                onClick={handleAddNoteClick}
              />
            </Box>

            {/* Search Bar and Sort Button */}
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
              padding="0px"
              marginBottom="20px"
            >
              <Flex
                width="100%"
                maxW="md"
                mx="auto"
                alignItems="center"
                justifyContent="space-between"
              >
                <Input
                  type="text"
                  placeholder="Search your notes"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  flex={1}
                  marginRight="10px"
                  backgroundColor="#303133"
                  color="white"
                  fontSize="1rem"
                  outline="none"
                  borderRadius="full"
                  border="none"
                />
                <Button
                  onClick={toggleSortOrder}
                  backgroundColor="#303133"
                  color="white"
                  height="46px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  _hover={{
                    backgroundColor: '#303133',
                    color: 'white',
                  }}
                >
                  <CalendarIcon marginRight="5px" />
                  {sortOrder === 'ascending' ? '↑' : ' ↓'}
                </Button>
              </Flex>
            </Flex>

            {/* Notes List */}
            {loading ? (
              <VStack spacing={3} align="stretch" flex="1">
                {[...Array(5)].map((_, index) => (
                  <ShimmerLoadingCard key={index} />
                ))}
              </VStack>
            ) : filteredNotes.length === 0 ? (
              <VStack spacing={3} align="stretch" flex="1">
                <Text color="whiteAlpha.600" textAlign="center">
                  No notes found
                </Text>
              </VStack>
            ) : (
              <VStack spacing={3} align="stretch" flex="1">
                {filteredNotes.map((note) => (
                  <NoteCard
                    key={note.noteId}
                    note={note}
                    isSelected={selectedNote && selectedNote.noteId === note.noteId}
                    onClick={() => handleNoteClick(note)}
                  />
                ))}
              </VStack>
            )}
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default NotesView;
