import React, { useState, useRef, useEffect, useCallback } from 'react';
import { 
  Box, 
  Flex, 
  Text, 
  Spinner,
  useMediaQuery,
  Icon,
  Button,
  useDisclosure,
  InputGroup,
  Image,
  VStack,
  Grid,
  HStack
} from '@chakra-ui/react';
import { ArrowForwardIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from './Header';
import Markdown from "react-markdown";
import Cookies from 'js-cookie';
import { useAuth } from "../auth/AuthContext";
import Login from "./Login";
import { AutoResizeTextarea } from './AutoResizeTextarea';
import QANotesView from './AskHistory';
import { FaUtensils, FaMapMarkerAlt, FaCommentAlt, FaQuestion } from 'react-icons/fa';
import { MdMovie } from 'react-icons/md';
import { GiChopsticks } from 'react-icons/gi';
import { AskLoginPrompt } from './LoginPrompts';

const messageDisplayStyles = {
  bg: "black",
  color: "white",
  p: 4,
  rounded: "lg",
  shadow: "sm",
  fontSize: "16px",
  textAlign: "left",
  className: "markdown-content"
};

// Loading Screen Component
const LoadingScreen = () => {
  return (
    <Box h="100vh" bg="black">
      {/* Header shimmer */}
      <Box 
        position="fixed"
        top={0}
        left={0}
        right={0}
        h="64px"
        className="shimmer-container"
      >
        <Box className="shimmer-effect" />
      </Box>

      {/* Main content area */}
      <Box 
        display="flex" 
        h="calc(100vh - 64px)" 
        w="100%" 
        pt="64px"
      >
        {/* Left column */}
        <Box 
          w="50%" 
          h="100%" 
          borderRight="1px" 
          borderColor="whiteAlpha.200"
          display="flex"
          flexDirection="column"
        >
          {/* Message area shimmer */}
          <Box 
            flex="1" 
            p={4}
            className="shimmer-container"
          >
            <Box className="shimmer-effect" />
          </Box>
          
          {/* Input area shimmer */}
          <Box p={4}>
            <Box 
              h="50px" 
              borderRadius="24px"
              className="shimmer-container"
            >
              <Box className="shimmer-effect" />
            </Box>
          </Box>
        </Box>

        {/* Divider */}
        <Box w="5px" h="100%" bg="whiteAlpha.200" />

        {/* Right column */}
        <Box 
          flex="1" 
          className="shimmer-container"
        >
          <Box className="shimmer-effect" />
        </Box>
      </Box>
    </Box>
  );
};

const ChatInput = ({ value, onChange, onSubmit, disabled, isStreaming, onStopClick, handleSubmit }) => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const textareaRef = useRef(null);
  
  return (
    <InputGroup 
      size="md" 
      w="full" 
      maxW="500px"
      position="relative"
      bg="#1A202C"
      p={4}
      borderRadius="md"
    >
      <AutoResizeTextarea
        ref={textareaRef}
        placeholder="Type your message..."
        value={value}
        onChange={onChange}
        color="black"
        backgroundColor="white"
        border="none"
        _placeholder={{ color: "gray.500" }}
        paddingEnd="80px"  // Significantly increased right padding to prevent text overlap
        paddingRight="80px"  // Adding explicit paddingRight as a fallback
        minH="45px"
        maxH="160px"
        lineHeight="1.2"
        pt="12px"
        pb="12px"
        px="14px"
        borderRadius="24px"
        disabled={disabled}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSubmit();
          }
        }}
        resize="none"
        width="100%"
        sx={{
          whiteSpace: 'pre-wrap',  // Preserve line breaks
          overflowWrap: 'break-word',  // Break long words
          textOverflow: 'ellipsis',  // Add ellipsis for overflow
        }}
      />
      <Button
        onClick={isStreaming ? onStopClick : handleSubmit}
        disabled={!value.trim()}
        position="absolute"
        right="28px"  // Moved button slightly more to the right
        top="50%"
        transform="translateY(-50%)"
        height="30px"
        width="44px"
        backgroundColor={isStreaming ? "gray.600" : "#FF0050"}
        _hover={{ bg: isStreaming ? "gray.700" : "#FF3371" }}
        color="white"
        borderRadius="full"
        zIndex={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {isStreaming ? (
          <Image src="/assets/ask_stop_icon.svg" alt="Stop" boxSize="16px"/>
        ) : (
          <Image src="/assets/ask_send_icon.svg" alt="Send" boxSize="18px"/>
        )}
      </Button>
    </InputGroup>
  );
};

// Main Chat Interface Component
const ChatInterface = () => {
  const { authState } = useAuth(); // Use the global AuthContext
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [leftColumnWidth, setLeftColumnWidth] = useState(50);
  const [isMobile] = useMediaQuery("(max-width: 768px)", {
    ssr: true,
    fallback: false
  });
  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const dividerRef = useRef(null);
  const chatContainerRef = useRef(null);
  const fetchController = useRef(new AbortController());
  const chatMessagesRef = useRef(null);
  const navigate = useNavigate();


  const handleMouseMove = useCallback((e) => {
    if (chatContainerRef.current) {
      const containerRect = chatContainerRef.current.getBoundingClientRect();
      const newWidth = ((e.clientX - containerRect.left) / containerRect.width) * 100;
      setLeftColumnWidth(Math.min(Math.max(newWidth, 30), 70));
    }
  }, []);

  const handleMouseUp = useCallback(() => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove]);

  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove, handleMouseUp]);

  const handleInputChange = useCallback((e) => {
    setMessage(e.target.value);
  }, []);

  const handleStopClick = useCallback(() => {
    fetchController.current.abort();
    setIsStreaming(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!message.trim() || isStreaming) return;

    setIsStreaming(true);
    setResponse('');
    
    fetchController.current.abort();
    fetchController.current = new AbortController();
    const { signal } = fetchController.current;

    try {
      const userId = Cookies.get('userId');
      
      if (!userId) {
        throw new Error("User ID not found");
      }

      const res = await fetch(`/api/ask`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId, query: message }),
        signal,
      });

      if (!res.ok || !res.body) {
        const errorText = await res.text();
        console.error("API Error:", res.status, res.statusText, errorText);
        throw new Error("Network response was not ok");
      }

      let fullResponse = "";
      const decoder = new TextDecoder();

      res.body.pipeTo(
        new WritableStream({
          write(chunk) {
            const chunkContent = decoder.decode(chunk);
            fullResponse += chunkContent;
            setResponse(prevResponse => prevResponse + chunkContent);
          },
          close() {
            setIsStreaming(false);
            setMessage('');
          },
          abort(err) {
            setIsStreaming(false);
            console.error("Stream aborted:", err);
          },
        })
      );
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Fetch error:", error);
        setResponse("Failed to fetch response. Please try again.");
      }
      setIsStreaming(false);
    }
  }, [message, isStreaming]);


  useEffect(() => {
    if (!isMobile) {
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [isMobile, handleMouseMove, handleMouseUp]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (chatMessagesRef.current) {
        chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
      }
    };
    const timeoutId = setTimeout(scrollToBottom, 100);
    return () => clearTimeout(timeoutId);
  }, [response]);

  useEffect(() => {
    return () => {
      if (fetchController.current) {
        fetchController.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLayoutReady(true);
    }, 10);
    
    return () => clearTimeout(timeoutId);
  }, []);

  // Render loading state while maintaining layout
  if (!isLayoutReady || authState.isLoading) {
    return <LoadingScreen />;
  }

  if (!authState.isLoading && !authState.userId) {
    return (
      <Box h="100vh" bg="black">
          <Box w="100%" h="full" borderRight="1px" borderColor="whiteAlpha.200">
            <AskLoginPrompt onSignInClick={() => navigate('/login')} />
          </Box>
      </Box>
    );
  }

  if (isMobile) {
    return (
      <Box 
        h="100vh" 
        bg="black" 
        position="fixed" 
        top="48px"
        left={0} 
        right={0} 
        bottom={0}
        overflowY="hidden"  // Prevent body scroll
      > 
        <Flex direction="column" h="full" position="relative">
          {/* Message Container - Add padding bottom to account for input height */}
          <Box 
            ref={chatMessagesRef}
            flex="1" 
            w="full"
            p={4}
            pb="145px"  // Increased padding to prevent content hiding
            bg="black"
            overflowY="auto"
            sx={{
              // Enable smooth scrolling and momentum
              WebkitOverflowScrolling: 'touch',
              scrollBehavior: 'smooth',
              // Optimize performance
              transform: 'translateZ(0)',
              backfaceVisibility: 'hidden',
              perspective: 1000,
              // Prevent content from getting stuck under rubber band effect
              overscrollBehavior: 'contain',
              // Enable vertical scrolling while preventing horizontal
              touchAction: 'pan-y pinch-zoom',
              // Custom scrollbar styling
              '&::-webkit-scrollbar': {
                width: '4px',
                display: 'none',
              },
              // Remove userSelect: 'none' to allow text selection
              // Ensure GPU acceleration
              willChange: 'transform',
              // Reduce friction
              '&:after': {
                content: '""',
                display: 'block',
                height: '1px',
                width: '100%',
                background: 'transparent',
              }
            }}
          >
            {(response || isStreaming) && (
              <Box 
                {...messageDisplayStyles}
                mb={6}
                sx={{
                  // Optimize rendering performance
                  transform: 'translateZ(0)',
                  backfaceVisibility: 'hidden',
                  willChange: 'transform',
                  // Prevent any potential paint issues
                  isolation: 'isolate',
                  // Ensure smooth font rendering
                  WebkitFontSmoothing: 'antialiased',
                  MozOsxFontSmoothing: 'grayscale',
                  // Explicitly enable text selection
                  userSelect: 'text',
                  WebkitUserSelect: 'text',
                  cursor: 'text',
                  // Enable touch callout for iOS
                  WebkitTouchCallout: 'default'
                }}
              >
                {isStreaming && !response ? (
                  <Spinner size="md" />
                ) : (
                  <Markdown>{response + (isStreaming ? " ⚪" : "")}</Markdown>
                )}
              </Box>
            )}
          </Box>
          
          {/* Input Container - Fixed positioning with high z-index */}
          <Box 
            position="fixed"
            bottom={0}
            left={0}
            right={0}
            bg="#1A202C"
            borderTop="1px"
            borderColor="whiteAlpha.200"
            display="flex"
            justifyContent="center"
            alignItems="center"
            minH="80px"
            px={4}
            zIndex={1000}  // Ensure it stays above content
            pb="env(safe-area-inset-bottom)"  // Handle iPhone notch
          >
            <ChatInput
              value={message}
              onChange={handleInputChange}
              onSubmit={handleSubmit}
              disabled={isStreaming}
              isStreaming={isStreaming}
              onStopClick={handleStopClick}
              handleSubmit={handleSubmit}
            />
          </Box>
        </Flex>
      </Box>
    );
  }

  return (
    <Box 
      h="calc(100vh - 64px)"  // Account for desktop header height
      bg="black" 
      position="fixed" 
      top="64px"  // Position below header
      left="200px"  // Account for sidebar width
      right={0} 
      bottom={0}
      overflow="hidden"
    >
      <Flex w="full" h="full">
        <Box 
          w={`${leftColumnWidth}%`} 
          h="full" 
          borderRight="1px" 
          borderColor="whiteAlpha.200"
          position="relative"
          display="flex"
          flexDirection="column"
        >
          <Box 
            ref={chatMessagesRef}
            flex="1"
            p={4}
            overflowY="auto"
            mb="80px"
          >
            {(response || isStreaming) ? (
              <Box {...messageDisplayStyles}>
                {isStreaming && !response ? (
                  <Spinner size="md" />
                ) : (
                  <Markdown>{response + (isStreaming ? " ⚪" : "")}</Markdown>
                )}
              </Box>
            ) : (
              <Flex align="center" justify="center" h="full">
                <Text color="gray.500" fontSize="xl">Ask Anything</Text>
              </Flex>
            )}
          </Box>

          <Box 
            position="absolute"
            bottom={0}
            left={0}
            right={0}
            bg="#1A202C"
            borderTop="1px"
            borderColor="whiteAlpha.200"
            display="flex"
            alignItems="center"
            justifyContent="center"
            minH="80px"
            width="100%"
          >
            <ChatInput
              value={message}
              onChange={handleInputChange}
              onSubmit={handleSubmit}
              disabled={isStreaming}
              isStreaming={isStreaming}
              onStopClick={handleStopClick}
              handleSubmit={handleSubmit}
            />
          </Box>
        </Box>

        <Box
          ref={dividerRef}
          w="5px"
          h="full"
          bg="whiteAlpha.200"
          cursor="col-resize"
          onMouseDown={handleMouseDown}
          _hover={{ bg: 'whiteAlpha.400' }}
        />

        <Box 
          flex="1" 
          h="full" 
          overflowY="auto"
        >
          <Box pt={4}> {/* Add padding top to account for header overlap */}
            <QANotesView />
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default ChatInterface;


// CSS for shimmer effect (add to your CSS file)
const shimmerStyles = `
.shimmer-container {
  position: relative;
  overflow: hidden;
  background: #1a1a1a;
}

.shimmer-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
`;