import React, { useState } from 'react';
import {
  Box,
  Flex,
  Button,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  HStack,
  Text,
  Icon,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../auth/firebaseConfig.ts';
import { useAuth } from '../auth/AuthContext';
import Cookies from 'js-cookie';
import Logo from '../../public/assets/sharedot_logo.svg';
import CustomIcon from '../../public/assets/menuButtonCircles.svg';
import { FaLinkedin, FaTwitter } from 'react-icons/fa';

const Header = () => {
  const { authState, setAuthInfo } = useAuth();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const navigate = useNavigate();
  
  const mainMenuItems = [
    { name: 'Discover', href: '/' },
    { name: 'Ask', href: '/ask' },
    { name: 'Notes', href: '/notes' },
  ];

  const legalMenuItems = [
    { name: 'Privacy Policy', href: '/privacypolicy' },
    { name: 'Terms of Use', href: '/termsofuse' },
  ];

  const socialLinks = [
    { icon: FaLinkedin, href: 'https://www.linkedin.com/company/sharedot/', label: 'LinkedIn' },
    { icon: FaTwitter, href: 'https://x.com/share_dot', label: 'Twitter' },
  ];

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setAuthInfo({ 
        userId: null,
        isAuthenticated: false,
        displayNameCompleted: false,
        onboardingQuestionsCompleted: false
      });
      Cookies.remove('userId');
      Cookies.remove('sessionToken');
      Cookies.remove('emailForSignIn');
      navigate('/');
    } catch (error) {
      console.error("Sign out error:", error);
    }
  };

  const SignInButton = ({ isMobile = false }) => (
    <Button
      onClick={() => navigate('/login')}
      bg="white"
      color="black"
      size={{ base: "md", md: "sm" }}
      fontWeight="medium"
      width={isMobile ? "80%" : "auto"}
      _hover={{
        bg: "gray.100"
      }}
    >
      Sign In
    </Button>
  );

  const SignOutButton = ({ isMobile = false }) => (
    <Button
      onClick={handleSignOut}
      variant="outline"
      bg="black"
      color="white"
      borderColor="white"
      size={{ base: "md", md: "sm" }}
      fontWeight="medium"
      width={isMobile ? "80%" : "auto"}
      _hover={{
        bg: "gray.900"
      }}
    >
      Sign Out
    </Button>
  );

  return (
    <Box 
      as="header" 
      position="fixed"
      top="0"
      left="0"
      right="0"
      height={{ base: "48px", md: "64px" }}
      zIndex="1000"
      bg="black"
      borderBottom="1px solid"
      borderColor="whiteAlpha.200"
    >
      <Flex
        height="100%"
        px={4}
        align="center"
        justify="space-between"
        maxW="100%"
      >
        <Flex align="center" height="100%">
          <Link to="/home">
            <img 
              src={Logo} 
              alt="SHAREDOT"
              style={{ 
                height: '18px',
                marginLeft: '16px',
                display: 'block',
              }} 
            />
          </Link>
        </Flex>

        {/* Desktop Auth Button */}
        <Box display={{ base: 'none', md: 'block' }}>
          {authState.userId ? <SignOutButton /> : <SignInButton />}
        </Box>

        {/* Mobile menu button */}
        <Flex 
          display={{ base: 'flex', md: 'none' }}
          align="center"
          height="100%"
        >
          <Button 
            variant="ghost" 
            onClick={onDrawerOpen}
            p={0}
            minW="32px"
            height="32px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            _hover={{ bg: 'transparent' }}
          >
            <img 
              src={CustomIcon} 
              alt="Menu"
              style={{ 
                height: '24px',
                width: '24px',
              }} 
            />
          </Button>
        </Flex>
      </Flex>

      {/* Desktop Sidebar */}
      <Box
        display={{ base: 'none', md: 'block' }}
        position="fixed"
        left={0}
        top="64px"
        height="calc(100vh - 64px)"
        width="200px"
        bg="black"
        borderRight="1px solid"
        borderColor="whiteAlpha.200"
        zIndex="999"
      >
        <Flex 
          direction="column" 
          height="100%"
          py={8}
        >
          {/* Main Navigation Items */}
          <VStack spacing={0} align="stretch">
          {mainMenuItems.map((item) => (
            <Box
              key={item.name}
              as="a"
              href={item.href}
              color="white"
              px={6}
              py={4}
              position="relative"
              border="1px solid transparent"  // Add this to maintain consistent spacing
              mx="8px"  // Move this outside of hover
              _hover={{
                color: 'black',
                bg: 'white',
                borderRadius: '4px',
                border: '1px solid black',
                transition: 'none'
              }}
              fontWeight="semibold"
              textAlign="left"
            >
              {item.name}
            </Box>
          ))}
        </VStack>

          {/* Footer Section */}
          <VStack spacing={2} mt="auto" mb={2}>
            {/* Legal Links - More compact layout */}
            <VStack spacing={2}>
              {legalMenuItems.map((item) => (
                <Box
                  key={item.name}
                  as="a"
                  href={item.href}
                  color="#999999"
                  fontSize="sm"
                  _hover={{ color: 'white' }}
                  textAlign="center"
                >
                  {item.name}
                </Box>
              ))}
            </VStack>

            {/* Social Links - Cleaner spacing */}
            <HStack spacing={4} justify="center">
              {socialLinks.map((social) => (
                <Box
                  key={social.label}
                  as="a"
                  href={social.href}
                  color="#666666"
                  _hover={{ color: 'white' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon as={social.icon} boxSize={4} />
                </Box>
              ))}
            </HStack>

            {/* Copyright - Refined styling */}
            <Text 
              color="#808080" 
              fontSize="xs"
              textAlign="center"
            >
              © 2025 ShareDot
            </Text>
          </VStack>
        </Flex>
      </Box>

      {/* Mobile Drawer */}
      <Drawer isOpen={isDrawerOpen} onClose={onDrawerClose} placement="right">
        <DrawerOverlay />
        <DrawerContent bg="black" position="relative">
          <DrawerCloseButton 
            color="gray.300"
            zIndex="10"
            position="absolute"
            right="8px"
            top="8px"
          />
          <DrawerBody p={0} overflow="hidden">
            <Flex 
              direction="column" 
              height="100%"  // Changed from 100vh to 100%
              py={6}        // Reduced from py={8}
            >
              {/* Top Section: Navigation + Sign In/Out */}
              <VStack spacing={4} align="stretch">  {/* Reduced spacing from 6 */}
                {/* Main Navigation Items */}
                <VStack spacing={3} align="stretch">  {/* Reduced spacing from 4 */}
                  {mainMenuItems.map((item) => (
                    <Box
                      key={item.name}
                      as="a"
                      href={item.href}
                      color="white"
                      px={6}
                      py={3}     // Reduced from py={4}
                      _hover={{
                        color: 'black',
                        bg: 'white',
                        borderRadius: '4px',
                        border: '1px solid black',
                        mx: '8px'
                      }}
                      fontWeight="semibold"
                    >
                      {item.name}
                    </Box>
                  ))}
                </VStack>

                {/* Sign In/Out Button - Positioned after main nav */}
                <Box px={6} display="flex" justifyContent="center">
                  {authState.userId ? <SignOutButton isMobile /> : <SignInButton isMobile />}
                </Box>
              </VStack>

              {/* Bottom Section: Legal + Social + Copyright */}
              <VStack spacing={4} mt="auto">  {/* Reduced spacing from 6 */}
                {/* Legal Links */}
                <VStack spacing={1}>  {/* Reduced spacing from 2 */}
                  {legalMenuItems.map((item) => (
                    <Box
                      key={item.name}
                      as="a"
                      href={item.href}
                      color="#999999"
                      fontSize="sm"
                      _hover={{ color: 'white' }}
                    >
                      {item.name}
                    </Box>
                  ))}
                </VStack>

                {/* Social Links */}
                <HStack spacing={4} justify="center">
                  {socialLinks.map((social) => (
                    <Box
                      key={social.label}
                      as="a"
                      href={social.href}
                      color="#666666"
                      _hover={{ color: 'white' }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon as={social.icon} boxSize={4} />
                    </Box>
                  ))}
                </HStack>

                {/* Copyright */}
                <Text 
                  color="#808080" 
                  fontSize="xs"
                  textAlign="center"
                  mb={2}  // Added bottom margin
                >
                  © 2025 ShareDot
                </Text>
              </VStack>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Header;