// routes/Login.tsx
import React, { useState } from 'react';
import {
  Box,
  Flex,
  Input,
  Button,
  Text,
  IconButton,
  useToast,
  useMediaQuery,
  HStack,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { auth } from '../auth/firebaseConfig';
import Cookies from 'js-cookie';
import Logo from '../../public/assets/sharedot_circle_mid_logo.svg';

const Login = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const toast = useToast();
  const navigate = useNavigate();

  const handleSignIn = async () => {
    if (!email) {
      toast({
        title: "Please enter an email address.",
        status: "warning",
        duration: 3000,
      });
      return;
    }

    setIsLoading(true);
    try {
      await sendSignInLinkToEmail(auth, email, {
        url: `${window.location.origin}/`,
        handleCodeInApp: true,
      });
      Cookies.set('emailForSignIn', email, { expires: 1 });
      toast({
        title: "Sign-in link sent!",
        description: "Please check your email to complete sign-in.",
        status: "success",
        duration: 5000,
      });
      navigate(-1); // Go back to previous page
    } catch (error) {
      toast({
        title: "Error sending sign-in link",
        description: error.message,
        status: "error",
        duration: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const LegalLinks = () => {
    const navigate = useNavigate();
    
    return (
      <HStack spacing={6} justify="center" mt={8}>
        <Button
          variant="link"
          color="whiteAlpha.700"
          fontSize="sm"
          onClick={() => navigate('/termsofuse')}
          _hover={{ color: 'white', textDecoration: 'none' }}
        >
          Terms of Use
        </Button>
        <Button
          variant="link"
          color="whiteAlpha.700"
          fontSize="sm"
          onClick={() => navigate('/privacypolicy')}
          _hover={{ color: 'white', textDecoration: 'none' }}
        >
          Privacy Policy
        </Button>
      </HStack>
    );
  };
  

  if (isMobile) {
    return (
      <Box minH="100vh" bg="black" p={4}>
        <IconButton
          aria-label="Back"
          icon={<ArrowBackIcon />}
          position="absolute"
          left={4}
          top={4}
          variant="ghost"
          color="white"
          onClick={handleBackClick}
          _hover={{ bg: 'whiteAlpha.200' }}
        />
        
        <Flex direction="column" align="center" pt={16}>
          <img 
            src={Logo} 
            alt="SHAREDOT"
            style={{ 
              height: '225px',
              marginBottom: '48px'
            }} 
          />
          
          <Box w="full" maxW="400px">
            <Text
              color="white"
              fontSize="xl"
              fontWeight="medium"
              textAlign="center"
              mb={8}
            >
              Enter an email address to continue
            </Text>
            
            <Input
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="lg"
              bg="gray.800"
              border="1px solid"
              borderColor="whiteAlpha.300"
              color="white"
              _placeholder={{ color: 'gray.500' }}
              _hover={{ borderColor: 'whiteAlpha.400' }}
              _focus={{
                borderColor: "blue.300",
                boxShadow: "0 0 0 1px #63B3ED",
              }}
              mb={4}
            />
            
            <Button
              width="full"
              size="lg"
              onClick={handleSignIn}
              isLoading={isLoading}
              loadingText="Sending..."
              bg="white"
              color="black"
              _hover={{ bg: 'gray.200' }}
            >
              Continue
            </Button>
            <LegalLinks />
          </Box>
        </Flex>
      </Box>
    );
  }

  return (
    <Flex minH="100vh" bg="black">
      {/* Left Side - Logo */}
      <Flex
        w="50%"
        align="center"
        justify="center"
        borderRight="1px"
        borderColor="whiteAlpha.200"
      >
        <img 
          src={Logo} 
          alt="SHAREDOT"
          style={{ 
            height: '300px'
          }} 
        />
      </Flex>

      {/* Right Side - Login Form */}
      <Flex
        w="50%"
        direction="column"
        justify="center"
        p={16}
        position="relative"
      >
        <IconButton
          aria-label="Back"
          icon={<ArrowBackIcon />}
          position="absolute"
          left={6}
          top={6}
          variant="ghost"
          color="white"
          onClick={handleBackClick}
          _hover={{ bg: 'whiteAlpha.200' }}
        />

        <Box maxW="440px" mx="auto" w="full">
          <Text
            color="white"
            fontSize="2xl"
            fontWeight="medium"
            mb={8}
          >
            Please enter an email address to continue
          </Text>

          <Input
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            size="lg"
            bg="gray.800"
            border="1px solid"
            borderColor="whiteAlpha.300"
            color="white"
            _placeholder={{ color: 'gray.500' }}
            _hover={{ borderColor: 'whiteAlpha.400' }}
            _focus={{
              borderColor: "blue.300",
              boxShadow: "0 0 0 1px #63B3ED",
            }}
            mb={4}
          />

          <Button
            width="full"
            size="lg"
            onClick={handleSignIn}
            isLoading={isLoading}
            loadingText="Sending..."
            bg="white"
            color="black"
            _hover={{ bg: 'gray.200' }}
          >
            Continue
          </Button>
          <LegalLinks />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Login;