// LoginPrompts.jsx
import React from 'react';
import { 
  Box, 
  VStack, 
  Text, 
  Button, 
  Icon, 
  Flex, 
  useMediaQuery 
} from '@chakra-ui/react';
import { 
  BookOpen, 
  MessageSquare,
  Compass,
  Search,
  Brain,
  Edit3,
  Share2,
  Star
} from 'lucide-react';

const LoginPromptTemplate = ({ title, subtitle, features, buttonText, onSignInClick }) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  
  return (
    <Box 
      w="full" 
      bg="black" 
      color="white"
      p={{ base: 4, md: 0 }}
      pb={{ base: "120px", md: 0 }} // Ensure floating button doesn’t overlap
    >
      <VStack 
        spacing={{ base: 6, md: 8 }}
        maxW="3xl" 
        mx="auto" 
        align="center"
        justify="flex-start" // Avoid forcing height
      >
        <VStack spacing={{ base: 2, md: 4 }} textAlign="center">
          <Text 
            fontSize={{ base: "2xl", md: "5xl" }}
            fontWeight="bold"
            bgGradient="linear(to-r, white, gray.400)"
            bgClip="text"
          >
            {title}
          </Text>
          <Text 
            fontSize={{ base: "md", md: "xl" }}
            color="#808080"
            maxW="2xl"
            px={{ base: 2, md: 0 }}
          >
            {subtitle}
          </Text>
        </VStack>

        <Flex 
          direction={{ base: "column", md: "row" }} 
          gap={{ base: 4, md: 8 }}
          w="full" 
          justify="center"
        >
          {features.map((feature, index) => (
            <VStack 
              key={index} 
              spacing={{ base: 2, md: 4 }}
              p={{ base: 4, md: 6 }}
              bg="whiteAlpha.100"
              borderRadius="xl"
              flex="1"
              maxW={{ base: "full", md: "xs" }}
              transition="all 0.2s"
              _hover={{ bg: "whiteAlpha.200" }}
            >
              <Box 
                borderRadius="full" 
                bg="whiteAlpha.200"
                w={{ base: "40px", md: "48px" }}
                h={{ base: "40px", md: "48px" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icon as={feature.icon} boxSize={{ base: 5, md: 6 }} />
              </Box>
              <Text fontSize={{ base: "lg", md: "xl" }} fontWeight="bold">
                {feature.title}
              </Text>
              <Text 
                color="#808080" 
                textAlign="center"
                fontSize={{ base: "sm", md: "md" }}
              >
                {feature.description}
              </Text>
            </VStack>
          ))}
        </Flex>

        {/* Desktop button */}
        <Box display={{ base: 'none', lg: 'block' }}>
          <Button
            size="lg"
            bg="whiteAlpha.900"
            color="black"
            px={8}
            py={6}
            fontSize="lg"
            fontWeight="bold"
            _hover={{ bg: "white" }}
            onClick={onSignInClick}
          >
            {buttonText}
          </Button>
        </Box>
      </VStack>

      {/* Floating button container for mobile */}
      <Box 
        display={{ base: 'block', lg: 'none' }}
        position="fixed"
        bottom={0}
        left={0}
        right={0}
        bg="black"
        p={4}
        borderTop="1px solid"
        borderColor="whiteAlpha.200"
        zIndex={10}
      >
        <Button
          w="full"
          size="lg"
          bg="whiteAlpha.900"
          color="black"
          px={8}
          py={6}
          fontSize="md"
          fontWeight="bold"
          _hover={{ bg: "white" }}
          onClick={onSignInClick}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};

export const NotesLoginPrompt = ({ onSignInClick }) => {
  const notesFeatures = [
    {
      icon: BookOpen,
      title: "Capture Ideas",
      description: "Keep track of your favorite places, entertainment, and experiences"
    },
    {
      icon: Edit3,
      title: "Quick & Easy",
      description: "Write notes instantly with our clean, distraction-free editor"
    },
    {
      icon: Star,
      title: "Power Your Ask",
      description: "The more notes you add, the better your personalized recommendations"
    }
  ];

  return (
    <LoginPromptTemplate 
      title="Personal Notes"
      subtitle="Add notes about your interests and we'll help you discover more of what you love"
      features={notesFeatures}
      buttonText="Start Writing"
      onSignInClick={onSignInClick}
    />
  );
};

export const AskLoginPrompt = ({ onSignInClick }) => {
  const askFeatures = [
    {
      icon: Brain,
      title: "Smart Suggestions",
      description: "Get recommendations that perfectly match your tastes and preferences"
    },
    {
      icon: Search,
      title: "Ask Anything",
      description: "From restaurant picks to travel tips, tailored to your interests"
    },
    {
      icon: Compass,
      title: "Personal Guide",
      description: "Powered by your notes to give you spot-on recommendations"
    }
  ];

  return (
    <LoginPromptTemplate 
      title="Ask Anything"
      subtitle="Get personalized recommendations based on your notes and interests"
      features={askFeatures}
      buttonText="Start Asking"
      onSignInClick={onSignInClick}
    />
  );
};